import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const organistionSchema = Yup.object({
  bussinessName: Yup.string()
    .required("* Field is required")
    .matches(/^[aA-zZ '\s]+$/, "Only alphabets are allowed for this field ").max(50, 'Enter organization name under 50 characters'),
  email: Yup.string()
    .email("Invalid email addres")
    .required("* Field is required"),
  locationName: Yup.string(),
  website: Yup.string()
    .matches(
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Invalid website address"
    ),
    contact: Yup.string().matches(phoneRegExp, 'Contact number is not valid').max(12, 'Contact number must be 15 or less digits including country code')
    ,
});

export const vendorSchema = Yup.object({
  name: Yup.string()
    .required("* Field is required")
    .matches(/^[aA-zZ '\s]+$/, "Only alphabets are allowed for this field "),
  contactPerson: Yup.string()
    .min(3, "Must be atleast 3 characters")
    .max(30, "Must be less than 30 characters"),
  contact: Yup.string()
    .min(10, "Mobile number must be atleast 10 digits")
    .max(10, "Only 10 digits are allowed")
    .required("* Field is required"),
});

export const planSchema = Yup.object({
  planName: Yup.string()
    .required("* Field is required")
    .matches(/^[aA-zZ '\s]+$/, "Only alphabets are allowed for this field "),
  status: Yup.string().required("* Field is required"),
  currency: Yup.string().required("* Field is required"),
  discount: Yup.number()
    .typeError("it must be a number")
    .required("* Field is required")
    .min(0, "Must be atleast 0")
    .max(99, "Must be less than or equal to 100"),
  // .positive("must be greater than zero"),
  duration: Yup.number()
    .typeError("Must be number")
    .required("* Field is required")
    .integer("Must be number")
    .min(1, "Must be atleast 1")
    .max(12, "Must be less than or equal to 12")
    .positive("must be greater than zero"),
  priority: Yup.number()
    .typeError("it must be a number")
    .required("* Field is required")
    .min(1, "Must be atleast 1")
    .max(10, "Must be less than or equal to 10")
    .positive("must be greater than zero"),
});

export const couponSchema = Yup.object({
  subscriptionId: Yup.string()
    .min(2, "it should be atleast 2 characters")
    .required("Select a plan first"),
  discount: Yup.number("Only number are allowed")
    .required("* Field is required")
    .min(1, "Valid values 1 to 99")
    .max(99, "Valid values 1 to 99"),
});

export const settingsSchema = Yup.object({
  email: Yup.string()
    .email(" Invalid email address")
    .required("* Field is required"),
  oldPassword: Yup.string().required("* Field is required"),
  newPassword: Yup.string()
    .required("* Field is required")
    .min(8, " Password should be atleast 8 characters "),
});

export const GlobalsettingsSchema = Yup.object({
  pricePerSpINR: Yup.number().typeError("Must be a number"),
  pricePerSpUSD: Yup.number().typeError("Must be a number"),
  trialSalesperson: Yup.number()
    .typeError("Must be a number")
    .integer("Must be number"),
  trialDuration: Yup.number()
    .typeError("Must be number")
    .integer("Must be number")
    .min(1, "Must be atleast 1")
    .max(12, "Must be less than or equal to 12")
    .positive("must be greater than zero"),
});
