import axios from "axios";
import { SERVER_URL } from "../constants";
import { authStore } from "../store";
import toast from "react-hot-toast";

// Axios Client
const ApiClient = axios.create({
  baseURL: `${SERVER_URL}`,
});

ApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error?.response.status === 400) {
      toast.error(error.response.data);
    } else if (
      error?.response.status === 422 ||
      error.response.status === 403
    ) {
      toast.error("Something went wrong");
    } else if (error?.response.status === 500) {
      toast.error("Internal server error");
    } else {
      toast.error(error.response.data);
    }
  }
);

ApiClient.interceptors.request.use(
  (config) => {
    const store = authStore.getRawState();
    // console.log(store);
    config.headers.Authorization = `Bearer ${store.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Login api
export const login = (email, password) => {
  try {
    const resp = ApiClient.post("/admin/login", { email, password });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// All other apis

// organisation list
export const getOrganisations = ({
  offset,
  limit,
  sortField,
  query,
  status,
}) => {
  try {
    const resp = ApiClient.get("/admin/organisations", {
      params: {
        offset: offset || 1,
        limit: limit || 10,
        sortField,
        query,
        status,
        sortOrder: "DESC",
      },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// organisation by id
export const getOrganisationById = (id) => {
  try {
    const resp = ApiClient.get("/admin/organisation", {
      params: { orgId: id },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// edit organisation
export const editOrganisation = (values) => {
  console.log(values);
  try {
    const resp = ApiClient.put("/admin/organisation", values);
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// image upload
export const getImageLink = (id, result) => {
  // console.log(id, result);
  try {
    const resp = ApiClient.post("/image/upload", { id: id, imageData: result });
    return resp;
  } catch (e) {
    console.log("image error uploading", e);
  }
};

//vendor list
export const getVendors = (offset, limit, query, sortField) => {
  try {
    const resp = ApiClient.get("/admin/vendor/list", {
      params: {
        offset: offset,
        limit: limit,
        query: query,
        sortField: sortField,
        sortOrder: "ASC",
      },
    });
    return resp;
  } catch (e) {
    toast.error(e);
  }
};

//vendor by id
export const getVendorById = (id) => {
  try {
    const resp = ApiClient.get("/admin/vendor", {
      params: { vendorId: id },
    });
    return resp;
  } catch (e) {
    toast.error(e);
  }
};

//edit vendor
export const editVendor = (values) => {
  try {
    const resp = ApiClient.put("/admin/vendor", values);
    return resp;
  } catch (e) {
    toast.error(e);
  }
};

// plans list
export const getPlans = (offset, limit, query, sortField, status) => {
  try {
    const resp = ApiClient.get("/admin/subscription/plans", {
      params: {
        offset: offset,
        limit: limit,
        query: query,
        sortField: sortField,
        status: status,
      },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// add plan
export const addPlan = (values) => {
  try {
    const resp = ApiClient.post("/admin/subscription/plan", values);
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// edit plan
export const editPlan = (values) => {
  try {
    const resp = ApiClient.put("/admin/subscription/plan", values);
    return resp;
  } catch (e) {
    console.log(e);
  }
};

//plan by id
export const getPlanById = (id) => {
  try {
    const resp = ApiClient.get("/admin/subscription/plan", {
      params: { planId: id },
    });
    return resp;
  } catch (e) {}
};

// coupons list
export const getCoupons = (
  offset,
  limit,
  redeemStatus,
  couponStatus,
  planName
) => {
  try {
    const resp = ApiClient.get("/admin/coupons", {
      params: {
        offset: offset,
        limit: limit,
        codeStatus: redeemStatus,
        couponStatus: couponStatus,
        planName: planName,
      },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// add coupon
export const addCoupon = (values) => {
  try {
    const resp = ApiClient.post("/admin/coupon", values);
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// update coupon
export const editCoupon = (values) => {
  try {
    const resp = ApiClient.put("/admin/coupon", values);
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// subscriber list
export const getSubscribers = async (
  offset,
  limit,
  query,
  sortField,
  planName
) => {
  try {
    const resp = await ApiClient.get("/admin/subscribers", {
      params: {
        offset: offset,
        limit: limit,
        query: query,
        sortField: sortField,
        planName: planName,
      },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// get subscriber by id
export const getSubscriberById = (id) => {
  try {
    const resp = ApiClient.get("/admin/subscriber", {
      params: { organisationId: id },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// subscriber transaction list
export const getSubscriberTransactionList = (id, offset, limit) => {
  try {
    const resp = ApiClient.get("/org/transaction/list", {
      params: { orgId: id, offset: offset, limit: limit },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// get all transactions
export const getTransactionlist = ({
  offset,
  limit,
  sortField,
  status,
  orgId,
}) => {
  try {
    const resp = ApiClient.get("/admin/transactions", {
      params: {
        offset,
        limit,
        sortField,
        status,
        orgId,
      },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// get transaction by id
export const getTransactionById = (id) => {
  try {
    const resp = ApiClient.get("/admin/transaction", {
      params: { transactionId: id },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// Change Admin Settings

export const editSettings = (email, oldPassword, newPassword) => {
  try {
    const resp = ApiClient.post("/admin/password/change", {
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// dashboard stats
export const getDashboardStats = (fromDate, toDate) => {
  // console.log(fromDate, toDate);
  try {
    const resp = ApiClient.get("/admin/dashboard/stats", {
      params: { fromDate: fromDate, toDate: toDate },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

// subscription stats
export const getSubscriptionStats = () => {
  // console.log(fromDate, toDate);
  try {
    const resp = ApiClient.get("/admin/subscriber/stats", {
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};


//chart data
export const getOrgChartData = (fromDate, toDate) => {
  try {
    const resp = ApiClient.get("/admin/org-chart", {
      params: { fromDate: fromDate, toDate: toDate },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

export const getRevenueChartData = (fromDate, toDate) => {
  try {
    const resp = ApiClient.get("/admin/revenue-chart", {
      params: { fromDate: fromDate, toDate: toDate },
    });
    return resp;
  } catch (e) {
    console.log(e);
  }
};

export const getGlobalSettings = () => {
  try {
    const resp = ApiClient.get("/admin/settings");
    return resp;
  } catch (e) {
    console.log(e);
  }
};

export const updateGlobalSettings = (values) => {
  try {
    const resp = ApiClient.put("/admin/settings", values);
    return resp;
  } catch (e) {
    console.log(e);
  }
};
