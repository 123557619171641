import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { endOfMonth, startOfMonth, format } from "date-fns/esm";
import Button from "../components/Button";
import Stats from "../components/Stats";
import Cards from "../components/Cards";
import Charts from "../components/Charts";
import {
  getDashboardStats,
  getOrgChartData,
  getRevenueChartData,
  getSubscriptionStats,
} from "../services/api";
import Loader from "react-loader-spinner";
import millify from "millify";

function Dashboard() {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [orgCalendar, setOrgCalendar] = useState(false);
  const [revenueCalendar, setRevenueCalendar] = useState(false);
  const [data, setData] = useState();
  const [orgData, setOrgData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [isLoader, setLoader] = useState(true);
  const [dashboardLoader, setDashboardLoader] = useState(false);
  const [orgChartLoader, setOrgChartLoader] = useState(false);
  const [revenueChartLoader, setRevenueChartLoader] = useState(false);
  const [subsStats, setSubsStats] = useState();

  const [date, setDate] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);

  const [state, setState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);
  const [revenueState, setRevenueState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);

  const handleCalendar = () => {
    setOpenCalendar((o) => !o);
    if (openCalendar) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  };

  const handleOrgCalendar = () => {
    setOrgCalendar((prev) => !prev);
    if (orgCalendar) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  };

  const handleRevenueCalendar = () => {
    setRevenueCalendar((prev) => !prev);
    if (revenueCalendar) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  };

  useEffect(() => {
    filtering();
    orgFiltering();
    revenueFiltering();
    getSubsStats();
    setOpenCalendar(true);
    setOrgCalendar(true);
    setRevenueCalendar(true);
  }, []);

  const filtering = async () => {
    setDashboardLoader(true);
    handleCalendar();
    // console.log(date);
    try {
      const res = await getDashboardStats(
        Math.round(date[0].startDate.getTime() / 1000),
        Math.round(date[0].endDate.getTime() / 1000)
      );
      console.log(res.data);
      setData(res?.data);
      setTimeout(() => {
        setLoader(false);
        setDashboardLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setDashboardLoader(false);
    }
  };

  const getSubsStats = async () => {
    try {
      const res = await getSubscriptionStats();
      setSubsStats(res?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const orgFiltering = async () => {
    // console.log(state);
    setOrgChartLoader(true);
    handleOrgCalendar();
    try {
      const res = await getOrgChartData(
        Math.round(state[0].startDate.getTime() / 1000),
        Math.round(state[0].endDate.getTime() / 1000)
      );
      // console.log(res.data.data.organization);
      const orgChart = res?.data
      const modifiedData = orgChart?.map((item) => {
        const orgChartItemName = format(
          new Date(item?.timestamp * 1000),
          "dd MMM yy"
        );
        return {
          name: orgChartItemName,
          Total: item?.count,
        };
      });
      // console.log("modified Data", modifiedData);
      setOrgData(modifiedData);
      setTimeout(() => {
        setLoader(false);
        setOrgChartLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setOrgChartLoader(false);
    }
  };

  const revenueFiltering = async () => {
    // console.log(revenueState);
    setRevenueChartLoader(true);
    handleRevenueCalendar();
    try {
      const res = await getRevenueChartData(
        Math.round(revenueState[0].startDate.getTime() / 1000),
        Math.round(revenueState[0].endDate.getTime() / 1000)
      );
      console.log(res.data);
      const revenueChart = res?.data;
      const modifiedData = revenueChart?.map((item) => {
        const revenueChartItemName = format(
          new Date(item?.timestamp * 1000),
          "dd MMM yy"
        );
        return {
          name: revenueChartItemName,
          Total: item?.amount,
        };
      });
      // console.log("revenue", modifiedData);
      setRevenueData(modifiedData);
      setTimeout(() => {
        setLoader(false);
        setRevenueChartLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setRevenueChartLoader(false);
    }
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div className="w-full px-5 py-2 bg-lightBg">
      <div className="w-full flex items-center justify-start px-2 py-3 md:py-2 mb-3">
        <p className=" text-gray-800 font-Mulish font-bold text-xl">
          Dashboard
        </p>
      </div>
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full rounded flex flex-col max-w-full bg-white"
      >
        {dashboardLoader ? (
          <div className="flex items-center justify-center h-40 w-full bg-white rounded-lg">
            <Loader type="Oval" color="#FFBD39" height={30} width={30} />
          </div>
        ) : (
          <>
            <div className="flex items-center justify-end px-6 pt-3 space-x-4">
              <p className="font-light font-Mulish text-xs text-gray-500">
                {`From : ${date[0].startDate.toLocaleDateString()}`}
              </p>
              <p className="font-light font-Mulish text-xs text-gray-500">
                {`To : ${date[0].endDate.toLocaleDateString()}`}
              </p>
              <p
                className=" font-normal text-blue text-sm cursor-pointer font-Mulish"
                onClick={() => handleCalendar()}
              >
                Filter
              </p>
            </div>
            <Stats
              organistionCount={data?.orgCount || 0}
              vendorCount={data?.vendorCount || 0}
              spCount={data?.spCount || 0}
              totalRevenue={` $${millify(data?.revenue || 0)}`}
            />
            {!openCalendar ? (
              <>
                <div
                  className="fixed w-full min-h-screen z-10 inset-0"
                  onClick={() => handleCalendar()}
                ></div>
                <div className=" bg-white z-30 absolute border border-gray-200 rounded right-5 lg:top-20 top-32 md:right-10 md:px-0">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setDate([item.selection])}
                    ranges={date}
                    moveRangeOnFirstSelection={false}
                  />
                  <div className="flex items-center justify-center py-2">
                    <Button
                      text="Filter"
                      variant="primary"
                      click={() => filtering()}
                    />
                    <Button
                      text="Close"
                      variant="danger"
                      click={() => handleCalendar()}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full py-4 mt-4 px-4 rounded bg-white"
      >
        <p className="font-Mulish text-gray-800 font-medium text-xl">
          Subscribers
        </p>
        <div className="flex md:items-center md:space-x-24 pr-4 pl-4 pt-2 pb-5 md:pr-16 flex-col md:flex-row">
          <Cards
            heading={"No. of Subscribers"}
            totalCount={subsStats?.count || 0}
            period={"Total Subscribers"}
          />
          <Cards
            heading={"No. of Subscribers"}
            totalCount={subsStats?.paidNearExpiry || 0}
            period={"Near Expiry"}
          />
          <Cards
            heading={"No. of Subscribers"}
            totalCount={subsStats?.paidSubsExpired || 0}
            period={"Subscription Expired"}
          />
        </div>
      </div>
      <div style={{ boxShadow: "4px 1px 10px #e9e9e9" }}>
        <div className="grid grid-cols-1 md:grid-cols-2 my-4 py-4 px-4 bg-white md:h-96 rounded">
          <div className="h-60 md:h-72 mb-16 md:mb-4 md:pr-10 w-full relative">
            {orgChartLoader ? (
              <div className="flex items-center justify-center h-full w-full bg-white rounded-lg">
                <Loader type="Oval" color="#FFBD39" height={30} width={30} />
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <p className="pt-3 pb-6 px-2 text-gray-600 font-Mulish font-bold text-sm">
                    Organisations
                  </p>
                  <div className="flex items-center justify-end px-6 pt-3 pb-6 space-x-4">
                    <p className="font-light font-Mulish text-xs text-gray-500 hidden sm:flex">
                      {`From : ${state[0].startDate.toLocaleDateString()}`}
                    </p>
                    <p className="font-light font-Mulish text-xs text-gray-500 hidden sm:flex">
                      {`To : ${state[0].endDate.toLocaleDateString()}`}
                    </p>
                    <p
                      className=" font-normal text-blue text-sm cursor-pointer font-Mulish"
                      onClick={() => handleOrgCalendar()}
                    >
                      Filter
                    </p>
                  </div>
                </div>
                <Charts data={orgData} chartColor="#82ca9d" />
                {!orgCalendar ? (
                  <>
                    <div
                      className="fixed w-full min-h-screen z-10 inset-0"
                      onClick={() => handleOrgCalendar()}
                    ></div>
                    <div className="z-30 absolute border border-gray-200 rounded lg:left-1/3 w-max bg-white lg:-top-16 top-0   md:right-10 md:px-0 transition ease-in-out duration-500">
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                      />
                      <div className="flex items-center justify-center py-2">
                        <Button
                          text="Filter"
                          variant="primary"
                          click={() => orgFiltering()}
                        />
                        <Button
                          text="Close"
                          variant="danger"
                          click={() => handleOrgCalendar()}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <div className="h-60 md:h-72 mb-16 md:mb-4">
            {revenueChartLoader ? (
              <div className="flex items-center justify-center h-full w-full bg-white rounded-lg">
                <Loader type="Oval" color="#FFBD39" height={30} width={30} />
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <p className="pt-3 pb-6 text-gray-600 px-2 font-Mulish font-bold text-sm">
                    Revenue
                  </p>
                  <div className="flex items-center justify-end px-6 pt-3 pb-6 space-x-4">
                    <p className="font-light font-Mulish text-xs text-gray-500 hidden sm:flex">
                      {`From : ${revenueState[0].startDate.toLocaleDateString()}`}
                    </p>
                    <p className="font-light font-Mulish text-xs text-gray-500 hidden sm:flex">
                      {`To : ${revenueState[0].endDate.toLocaleDateString()}`}
                    </p>
                    <p
                      className=" font-normal text-blue text-sm cursor-pointer font-Mulish"
                      onClick={() => handleRevenueCalendar()}
                    >
                      Filter
                    </p>
                  </div>
                </div>
                <Charts data={revenueData} chartColor="#10b981" />
                {!revenueCalendar ? (
                  <>
                    <div
                      className="fixed w-full min-h-screen z-10 inset-0"
                      onClick={() => handleRevenueCalendar()}
                    ></div>
                    <div
                      className=" bg-white z-30 fixed border border-gray-200 rounded 
                lg:bottom-5 right-5 lg:right-2 bottom-6  md:right-10 md:px-0"
                    >
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setRevenueState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={revenueState}
                      />
                      <div className="flex items-center justify-center py-2">
                        <Button
                          text="Filter"
                          variant="primary"
                          click={() => revenueFiltering()}
                        />
                        <Button
                          text="Close"
                          variant="danger"
                          click={() => handleRevenueCalendar()}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
