import List from "../../components/List";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { getCoupons, getPlans } from "../../services/api";
import _ from "lodash";
import Modal from "../../components/Modal";
import Loader from "react-loader-spinner";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const CouponList = () => {
  const columns = [
    { id: "couponCode", label: "Coupon Code", minWidth: 140, maxWidth: 170 },
    {
      id: "codeStatus",
      label: "Radeem Status",
      minWidth: 140,
      maxWidth: 170,
      align: "center",
    },
    {
      id: "couponStatus",
      label: "Status",
      minWidth: 140,
      maxWidth: 170,
      align: "center",
    },
    { id: "discount", label: "Discount", minWidth: 70, maxWidth: 170 },
    // { id: "planName", label: "Plan Name", minWidth: 140, maxWidth: 170 },
    { id: "createdAt", label: "Date", minWidth: 140, maxWidth: 170 },
  ];

  const [rows, setRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [redeemStatus, setRedeemStatus] = useState("");
  const [coupon_status, setCouponStatus] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [isLoader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [planName, setPlanName] = useState("");

  const Toggle = () => {
    setRefresh((prev) => !prev);
  };

  const plansList = async () => {
    const res = await getPlans();
    console.log(res.data.data);
    setPlans(res?.data?.data);
  };

  const fetchData = async () => {
    try {
      const res = await getCoupons(
        page,
        rowsPerPage,
        redeemStatus,
        coupon_status,
        planName
      );
      const coupons = res?.data;
      console.log(res);
      const modifiedCoupons = coupons?.result?.map(
        ({ codeStatus, createdAt, discount, couponStatus, ...rest }) => {
          const radeemStatus = _.toLower(codeStatus);
          const status = _.toLower(couponStatus);
          // const planCategory = _.lowerCase(plan.planName);
          return {
            codeStatus:
              radeemStatus === "redeemed" ? (
                <div className="bg-green-100 rounded-full flex items-center justify-center text-green-900 text-xs w-full  py-1 px-1 font-Mulish">
                  {_.startCase(radeemStatus)}
                </div>
              ) : (
                <div className="bg-yellow-100 rounded-full flex items-center justify-center text-yellow-700 text-xs w-full py-1 px-1 font-Mulish">
                  {_.startCase(radeemStatus)}
                </div>
              ),
            createdAt: new Date(createdAt * 1000).toLocaleDateString(),
            discount: discount + "%",
            editCouponStatus: couponStatus,
            couponStatus:
              status === "active" ? (
                <div className="bg-green-100 rounded-full flex items-center justify-center text-green-900 text-xs w-full py-1 font-Mulish">
                  {_.startCase(status)}
                </div>
              ) : (
                <div className="bg-red-100 rounded-full flex items-center justify-center text-red-900 text-xs w-full py-1 font-Mulish">
                  {_.startCase(status)}
                </div>
              ),
            // planName: (
            //   <p
            //     className=" truncate w-32 font-Mulish cursor-pointer text-blue"
            //     onClick={() => history.push(`/plans/${plan.id}`)}
            //   >
            //     {_.startCase(planCategory)}
            //   </p>
            // ),
            ...rest,
          };
        }
      );
      // console.log(modifiedCoupons);
      setRows(modifiedCoupons);
      setTotalRow(res?.data?.count);
      setTimeout(() => {
        setLoader(false);
        setTableLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setTableLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    // plansList();
  }, [page, rowsPerPage, redeemStatus, coupon_status, refresh, planName]);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setTableLoader(true);
    // setLoader(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTableLoader(true);
  };

  const handleEdit = (row) => {
    // console.log(row);
    setIsOpen(true);
    setId(row.id);
    setStatus(row?.editCouponStatus);
    // history.push(`/plans/plan/${id}`);
  };

  const handleOpen = () => {
    setIsOpen(true);
    setId("");
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleredeemStatus = (e) => {
    setRedeemStatus(e.target.value);
    setTableLoader(true);
  };

  const handleCouponStatus = (e) => {
    // console.log(e.target.value);
    setCouponStatus(e.target.value);
    setTableLoader(true);
  };

  const filtering = (e) => {
    setPlanName(e.target.value);
    setTableLoader(true);
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full min-h-full bg-lightBg flex items-center justify-center px-2 md:px-5 py-2"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 py-2"
      >
        <div className="w-full rounded-lg flex  items-center justify-center lg:w-5/6 lg:m-auto lg:my-6 border-2 flex-col lg:mb-1 border-lightGray bg-white">
          <div className="flex items-start md:justify-between md:px-6 py-2 w-full flex-col justify-center  space-y-2 md:flex-row">
            <div className="flex md:items-center justify-center  w-full  md:px-0 px-6 my-2">
              <FiArrowLeftCircle
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={() => history.goBack()}
              />
              <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
                Coupons
              </p>
            </div>
            <div className="flex flex-col md:flex-row max-w-sm md:max-w-none md:space-x-2  w-full px-2 space-y-2 md:space-y-0">
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Coupon Status
                </p>
                <select
                  className=" w-full md:w-40 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={handleCouponStatus}
                >
                  <option value="" label="All" />
                  <option value="ACTIVE" label="Active" />
                  <option value="INACTIVE" label="Inactive" />
                </select>
              </div>
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Redeem Status
                </p>
                <select
                  className=" w-full md:w-40 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={handleredeemStatus}
                >
                  <option value="" label="All" />
                  <option value="REDEEMED" label="Redeemed" />
                  <option value="NOT_REDEEMED" label="Not Redeemed" />
                </select>
              </div>
              {/* <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Plan Name
                </p>
                <select
                  className=" w-full md:w-40 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={filtering}
                >
                  <option value="" label="All" />
                  {plans?.map((item) => {
                    const planCategory = _.lowerCase(item.planName);
                    return (
                      <option
                        value={item?.planName}
                        label={_.startCase(planCategory)}
                        key={item?.id}
                      />
                    );
                  })}
                </select>
              </div> */}
              <div className="flex items-end justify-end md:justify-center md:px-0 px-6">
                <Button
                  category="rounded"
                  variant="primary"
                  text="Add Coupon"
                  click={handleOpen}
                />
              </div>
            </div>
          </div>
          {isOpen && (
            <Modal
              isOpen={isOpen}
              onClose={handleClose}
              id={id}
              status={status}
              Toggle={Toggle}
            />
          )}
          <List
            columns={columns}
            rows={rows}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalRow={totalRow}
            rowsPerPage={rowsPerPage}
            page={page}
            handleEdit={handleEdit}
            tableLoader={tableLoader}
          />
        </div>
      </div>
    </div>
  );
};

export default CouponList;
